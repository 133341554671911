<template>
  <div class="grow center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <s-logo />
      <h1 v-if="$mq != 'sm'" class="d-flex green--text font-48">
        <div class="weight-300">secur</div>
        <div class="bold">Meet</div>
      </h1>
      <div class="missingText red--text" :class="{ 'green--text': green }">
        <div v-if="success">{{ $t("server." + success) }}</div>
      </div>
      <s-text-field
        block
        v-model="firstName"
        :label="$t('registration.first_name')"
      ></s-text-field>
      <s-text-field
        block
        v-model="lastName"
        :label="$t('registration.last_name')"
      ></s-text-field>
      <s-text-field
        block
        v-model="email"
        :label="$t('registration.email')"
      ></s-text-field>
      <s-btn type="submit" block class="green mt-15" height="40">
        {{ $t("registration.invite") }}
      </s-btn>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      success: "",
      green: false,
    };
  },
  methods: {
    validateEmail(email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateOnEnter() {
      this.validate();
    },
    async post() {
      let data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
      };
      this.success = "";
      this.green = false;

      if (this.firstName == "") {
        return (this.success = this.$t("server.enter_first_name"));
      }
      if (this.lastName == "") {
        return (this.success = this.$t("server.enter_last_name"));
      }
      if (this.email == "" || !this.validateEmail(this.email)) {
        return (this.success = this.$t("server.format_email"));
      }
      let response = await this.$store.dispatch("auth/invitation", data);
      if (response.status === 200) {
        this.green = true;
        this.success = this.$t("server.success");
      }
      if (response.status === "error") {
        this.success = response.errorCode;
      }
    },
  },
};
</script>


<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}
</style>
