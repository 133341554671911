<template>
<div class="h100 vh100 d-flex d-col">
  <TopToolbar /> 
  <InvitationComp />
</div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import InvitationComp from "@/components/Auth/Invitation/Invitation.vue";

export default {
  name: "Invitation",
  components: {
    InvitationComp,
    TopToolbar
  },
};
</script>

